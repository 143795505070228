import React from "react";
import { API } from "aws-amplify";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import randomColor from "randomcolor";
import ListItemIcon from "@mui/material/ListItemIcon";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { HexColorPicker } from "react-colorful";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useSelector, useDispatch } from "react-redux";
import {
  setChartDataset,
  setLiveDataset,
  setOpenEditDatasetModal,
  setHistoricDataDateList,
  setDisabledLineGraphs,
} from "../../Redux/HP";
import {
  MasterDefaultView,
  Modul1DefaultView,
  Modul2DefaultView,
  Modul3DefaultView,
  Modul4DefaultView,
} from "./DefaultGraphs";
import {
  canAccessSettingsCell,
  getRulesForModel,
} from "../../../util/getRulesForModel";

export const EditDatasetLogic = (webWorker, context) => {
  const dispatch = useDispatch();
  const {
    HPData,
    liveDataset,
    graphParamList,
    chartDataset,
    openEditDatasetModal,
    historicDataDateList,
    graphDate,
    disabledLineGraphs,
  } = useSelector((state) => state.heatpump);

  const [openMS, setOpenMS] = React.useState(false);
  const [openS1S, setOpenS1S] = React.useState(false);
  const [openS2S, setOpenS2S] = React.useState(false);
  const [openS3S, setOpenS3S] = React.useState(false);
  const [openS4S, setOpenS4S] = React.useState(false);
  const [openMH, setOpenMH] = React.useState(false);
  const [openS1H, setOpenS1H] = React.useState(false);
  const [openS2H, setOpenS2H] = React.useState(false);
  const [openS3H, setOpenS3H] = React.useState(false);
  const [openS4H, setOpenS4H] = React.useState(false);

  const [openColorPicker, setOpenColorPicker] = React.useState(false);
  const [colorPickerColor, setColorPickerColor] = React.useState("");
  const [colorPickerName, setColorPickerName] = React.useState("");
  const [disabledGraphList, setDisabledGraphList] = React.useState([]);

  const rulesPerSettingsCache = React.useRef({
    master: {
      checked: false,
      rules: null,
    },
    modul1: {
      checked: false,
      rules: null,
    },
    modul2: {
      checked: false,
      rules: null,
    },
    modul3: {
      checked: false,
      rules: null,
    },
    modul4: {
      checked: false,
      rules: null,
    },
  });

  const patchUserGraphSettings = async (Serialnumber, graphSettings) => {
    const newData = {
      Serialnumber,
      graphSettings,
    };
    const apiName = "ThermonovaAPI";
    const path = "/users/user/graph-settings";
    await API.post(apiName, path, {
      body: newData,
    });
  };

  let MasterSettings;
  let Modul1Settings;
  let Modul2Settings;
  let Modul3Settings;
  let Modul4Settings;
  let MasterHotspots;
  let Modul1Hotspots;
  let Modul2Hotspots;
  let Modul3Hotspots;
  let Modul4Hotspots;

  React.useEffect(() => {
    for (const value of Object.entries(liveDataset)) {
      //console.log(value[1].id)
      if (!disabledGraphList.includes(value[1].id)) {
        //console.log(value[1].id)
        setDisabledGraphList((oldArray) => [...oldArray, value[1].id]);
      }
    }
  }, [disabledGraphList, liveDataset]);

  const permissionFilter = (row, settingsKey) => {
    const cacheKey = settingsKey;
    let cacheValue = rulesPerSettingsCache.current[cacheKey];
    if (!cacheValue?.checked) {
      rulesPerSettingsCache.current[cacheKey].rules = getRulesForModel(
        HPData,
        settingsKey,
        context["hpAccessRules"]
      );
      rulesPerSettingsCache.current[cacheKey].checked = true;
    }
    return canAccessSettingsCell(
      rulesPerSettingsCache.current[cacheKey].rules,
      row,
      "r"
    );
  };

  const handleChangeDataset = (type) => {
    switch (type) {
      case "Master":
        dispatch(setChartDataset(MasterDefaultView["chartDataset"]));
        dispatch(setLiveDataset(MasterDefaultView["liveDataset"]));
        dispatch(
          setDisabledLineGraphs(MasterDefaultView["disabledLineGraphs"])
        );
        break;
      case "Modul1":
        dispatch(setChartDataset(Modul1DefaultView["chartDataset"]));
        dispatch(setLiveDataset(Modul1DefaultView["liveDataset"]));
        dispatch(
          setDisabledLineGraphs(Modul1DefaultView["disabledLineGraphs"])
        );
        break;
      case "Modul2":
        dispatch(setChartDataset(Modul2DefaultView["chartDataset"]));
        dispatch(setLiveDataset(Modul2DefaultView["liveDataset"]));
        dispatch(
          setDisabledLineGraphs(Modul2DefaultView["disabledLineGraphs"])
        );
        break;
      case "Modul3":
        dispatch(setChartDataset(Modul3DefaultView["chartDataset"]));
        dispatch(setLiveDataset(Modul3DefaultView["liveDataset"]));
        dispatch(
          setDisabledLineGraphs(Modul3DefaultView["disabledLineGraphs"])
        );
        break;
      case "Modul4":
        dispatch(setChartDataset(Modul4DefaultView["chartDataset"]));
        dispatch(setLiveDataset(Modul4DefaultView["liveDataset"]));
        dispatch(
          setDisabledLineGraphs(Modul4DefaultView["disabledLineGraphs"])
        );
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    if (webWorker !== null) {
      //console.log(chartDataset)
      webWorker.postMessage({ TimeSeries: chartDataset });
    }
  }, [webWorker, chartDataset]);

  if ("MS" in graphParamList) {
    MasterSettings = (
      <div>
        <ListItemButton
          key="MS"
          onClick={() => {
            setOpenMS(!openMS);
          }}
        >
          <ListItemText primary="Master Settings" />
          {openMS ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openMS} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.MS)
              ?.filter((row) =>
                permissionFilter(graphParamList["MS"][row], "master")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["MS"][row].id}
                    id={graphParamList["MS"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "MS",
                        graphParamList["MS"][row].id,
                        graphParamList["MS"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["MS"][row].id +
                        "  " +
                        graphParamList["MS"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("S1S" in graphParamList) {
    let varName;
    if (HPData["Model"].includes("Geo")) {
      varName = "Master Settings";
    } else {
      varName = "Module 1 Settings";
    }
    Modul1Settings = (
      <div>
        <ListItemButton
          key="S1S"
          onClick={() => {
            setOpenS1S(!openS1S);
          }}
        >
          <ListItemText primary={varName} />
          {openS1S ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS1S} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S1S)
              ?.filter((row) =>
                permissionFilter(graphParamList["S1S"][row], "modul1")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S1S"][row].id}
                    id={graphParamList["S1S"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S1S",
                        graphParamList["S1S"][row].id,
                        graphParamList["S1S"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S1S"][row].id +
                        "  " +
                        graphParamList["S1S"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("S2S" in graphParamList) {
    Modul2Settings = (
      <div>
        <ListItemButton
          key="S2S"
          onClick={() => {
            setOpenS2S(!openS2S);
          }}
        >
          <ListItemText primary="Module 2 Settings" />
          {openS2S ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS2S} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S2S)
              ?.filter((row) =>
                permissionFilter(graphParamList["S2S"][row], "modul2")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S2S"][row].id}
                    id={graphParamList["S2S"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S2S",
                        graphParamList["S2S"][row].id,
                        graphParamList["S2S"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S2S"][row].id +
                        "  " +
                        graphParamList["S2S"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }

  if ("S3S" in graphParamList) {
    Modul3Settings = (
      <div>
        <ListItemButton
          key="S3S"
          onClick={() => {
            setOpenS3S(!openS3S);
          }}
        >
          <ListItemText primary="Module 3 Settings" />
          {openS3S ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS3S} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S3S)
              ?.filter((row) =>
                permissionFilter(graphParamList["S3S"][row], "modul3")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S3S"][row].id}
                    id={graphParamList["S3S"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S3S",
                        graphParamList["S3S"][row].id,
                        graphParamList["S3S"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S3S"][row].id +
                        "  " +
                        graphParamList["S3S"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("S4S" in graphParamList) {
    Modul4Settings = (
      <div>
        <ListItemButton
          key="S4S"
          onClick={() => {
            setOpenS4S(!openS4S);
          }}
        >
          <ListItemText primary="Module 4 Settings" />
          {openS4S ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS4S} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S4S)
              ?.filter((row) =>
                permissionFilter(graphParamList["S4S"][row], "modul4")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S4S"][row].id}
                    id={graphParamList["S4S"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S4S",
                        graphParamList["S4S"][row].id,
                        graphParamList["S4S"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S4S"][row].id +
                        "  " +
                        graphParamList["S4S"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("MH" in graphParamList) {
    MasterHotspots = (
      <div>
        <ListItemButton
          key="MS"
          onClick={() => {
            setOpenMH(!openMH);
          }}
        >
          <ListItemText primary="Master Hotspots" />
          {openMH ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openMH} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.MH)
              ?.filter((row) =>
                permissionFilter(graphParamList["MH"][row], "master")
              )
              .map((row, i) => {
                //console.log(disabledGraphList)
                var inList;
                var modbusID = "MH" + graphParamList["MH"][row].id;
                if (disabledGraphList.includes(modbusID)) {
                  inList = true;
                } else {
                  inList = false;
                }

                return (
                  <ListItemButton
                    key={graphParamList["MH"][row].id}
                    id={graphParamList["MH"][row].id}
                    sx={{ pl: 4 }}
                    disabled={inList}
                    onClick={() => {
                      addToDatesetList(
                        "MH",
                        graphParamList["MH"][row].id,
                        graphParamList["MH"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["MH"][row].id +
                        "  " +
                        graphParamList["MH"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("S1H" in graphParamList) {
    let varName;
    if (HPData["Model"].includes("Geo")) {
      varName = "Master Hotspots";
    } else {
      varName = "Module 1 Hotspots";
    }
    Modul1Hotspots = (
      <div>
        <ListItemButton
          key="S1H"
          onClick={() => {
            setOpenS1H(!openS1H);
          }}
        >
          <ListItemText primary={varName} />
          {openS1H ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS1H} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S1H)
              ?.filter((row) =>
                permissionFilter(graphParamList["S1H"][row], "modul1")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S1H"][row].id}
                    id={graphParamList["S1H"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S1H",
                        graphParamList["S1H"][row].id,
                        graphParamList["S1H"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S1H"][row].id +
                        "  " +
                        graphParamList["S1H"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("S2H" in graphParamList) {
    Modul2Hotspots = (
      <div>
        <ListItemButton
          key="S2H"
          onClick={() => {
            setOpenS2H(!openS2H);
          }}
        >
          <ListItemText primary="Module 2 Hotspots" />
          {openS2H ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS2H} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S2H)
              ?.filter((row) =>
                permissionFilter(graphParamList["S2H"][row], "modul2")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S2H"][row].id}
                    id={graphParamList["S2H"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S2H",
                        graphParamList["S2H"][row].id,
                        graphParamList["S2H"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S2H"][row].id +
                        "  " +
                        graphParamList["S2H"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("S3H" in graphParamList) {
    Modul3Hotspots = (
      <div>
        <ListItemButton
          key="S3H"
          onClick={() => {
            setOpenS3H(!openS3H);
          }}
        >
          <ListItemText primary="Module 3 Hotspots" />
          {openS3H ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS3H} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S3H)
              ?.filter((row) =>
                permissionFilter(graphParamList["S3H"][row], "modul3")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S3H"][row].id}
                    id={graphParamList["S3H"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S3H",
                        graphParamList["S3H"][row].id,
                        graphParamList["S3H"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S3H"][row].id +
                        "  " +
                        graphParamList["S3H"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }
  if ("S4H" in graphParamList) {
    Modul4Hotspots = (
      <div>
        <ListItemButton
          key="S4H"
          onClick={() => {
            setOpenS4H(!openS4H);
          }}
        >
          <ListItemText primary="Module 4 Hotspots" />
          {openS4H ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openS4H} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(graphParamList.S4H)
              ?.filter((row) =>
                permissionFilter(graphParamList["S4H"][row], "modul4")
              )
              .map((row, i) => {
                return (
                  <ListItemButton
                    key={graphParamList["S4H"][row].id}
                    id={graphParamList["S4H"][row].id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      addToDatesetList(
                        "S4H",
                        graphParamList["S4H"][row].id,
                        graphParamList["S4H"][row].col2
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        graphParamList["S4H"][row].id +
                        "  " +
                        graphParamList["S4H"][row].col2
                      }
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  }

  const getDatasetList = () => {
    return (
      <List
        sx={{ width: "100%", maxWidth: 600, bgcolor: "background.paper" }}
        component="nav"
      >
        {Object.keys(liveDataset).map((name, i) => {
          let showname;
          switch (liveDataset[name].controller) {
            case "MH":
              showname = "Master: " + liveDataset[name].name;
              break;
            case "MS":
              showname = "Master: " + liveDataset[name].name;
              break;
            case "S1H":
              showname = "Module 1: " + liveDataset[name].name;
              break;
            case "S2H":
              showname = "Module 2: " + liveDataset[name].name;
              break;
            case "S3H":
              showname = "Module 3: " + liveDataset[name].name;
              break;
            case "S4H":
              showname = "Module 4: " + liveDataset[name].name;
              break;
            case "S1S":
              showname = "Module 1: " + liveDataset[name].name;
              break;
            case "S2S":
              showname = "Module 2: " + liveDataset[name].name;
              break;
            case "S3S":
              showname = "Module 3: " + liveDataset[name].name;
              break;
            case "S4S":
              showname = "Module 4: " + liveDataset[name].name;
              break;

            default:
              break;
          }

          return (
            <Box key={i} sx={{ display: "inline" }}>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                fullWidth
                sx={{ mb: 1 }}
              >
                <ListItemButton
                  key={"b" + i}
                  style={{ maxWidth: "60px" }}
                  onClick={() => {
                    setColorPickerColor(liveDataset[name].color);
                    setOpenColorPicker(true);
                    setColorPickerName(liveDataset[name]);
                  }}
                >
                  <ListItemIcon style={{ color: liveDataset[name].color }}>
                    <ColorLensIcon />
                  </ListItemIcon>
                </ListItemButton>
                <ListItemButton
                  key={"1" + i}
                  onClick={() => {
                    removeFromDatesetList(
                      liveDataset[name].controller,
                      liveDataset[name].adr,
                      i
                    );
                  }}
                >
                  <ListItemText primary={showname} />
                </ListItemButton>
              </ButtonGroup>
            </Box>
          );
        })}
      </List>
    );
  };

  const handleNewColor = () => {
    var color = colorPickerColor;
    var name = colorPickerName;
    //console.log(name)
    //console.log(liveDataset)
    var arrayID = liveDataset.findIndex((x) => {
      return x.id === name.id;
    });
    if (arrayID !== -1) {
      let newArr = [...liveDataset];
      newArr[arrayID] = {
        id: name.id,
        name: name.name,
        adr: name.adr,
        color: color,
        controller: name.controller,
      };
      dispatch(setLiveDataset(newArr));

      setColorPickerName({
        id: name.id,
        name: name.name,
        adr: name.adr,
        color: color,
        controller: name.controller,
      });
    }

    let newList = [];
    chartDataset[name.controller].forEach((element) => {
      if (name.adr === Object.keys(element)[0]) {
        newList.push({ [name.adr]: { color: color } });
      } else {
        newList.push(element);
      }
    });
    //setChartDataset(newDataset)
    let obj = { ...chartDataset };
    obj[name.controller] = newList;
    dispatch(setChartDataset(obj));
  };

  const showColorPicker = () => {
    return (
      <Dialog
        onClose={() => {
          handleNewColor();
          setOpenColorPicker(false);
        }}
        open={openColorPicker}
      >
        <DialogContent>
          <HexColorPicker
            color={colorPickerColor}
            onChange={(color) => {
              setColorPickerColor(color);
            }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const getParamsList = () => {
    let out;

    switch (HPData["Model"]) {
      case "Nova 110":
        out = (
          <div>
            {MasterHotspots}
            {Modul1Hotspots}
            {MasterSettings}
            {Modul1Settings}
          </div>
        );
        break;
      case "Nova 220":
        out = (
          <div>
            {MasterHotspots}
            {Modul1Hotspots}
            {Modul2Hotspots}
            {MasterSettings}
            {Modul1Settings}
            {Modul2Settings}
          </div>
        );
        break;
      case "Nova 330":
        out = (
          <div>
            {MasterHotspots}
            {Modul1Hotspots}
            {Modul2Hotspots}
            {Modul3Hotspots}
            {MasterSettings}
            {Modul1Settings}
            {Modul2Settings}
            {Modul3Settings}
          </div>
        );
        break;
      case "Nova 440":
        out = (
          <div>
            {MasterHotspots}
            {Modul1Hotspots}
            {Modul2Hotspots}
            {Modul3Hotspots}
            {Modul4Hotspots}
            {MasterSettings}
            {Modul1Settings}
            {Modul2Settings}
            {Modul3Settings}
            {Modul4Settings}
          </div>
        );
        break;
      case "Nature Energy":
        out = (
          <div>
            {MasterHotspots}
            {Modul1Hotspots}
            {Modul2Hotspots}
            {Modul3Hotspots}
            {Modul4Hotspots}
            {MasterSettings}
            {Modul1Settings}
            {Modul2Settings}
            {Modul3Settings}
            {Modul4Settings}
          </div>
        );
        break;
      case "GeoNova 26":
        out = (
          <div>
            {MasterHotspots}
            {Modul1Hotspots}
            {Modul2Hotspots}
            {Modul3Hotspots}
            {Modul4Hotspots}
            {MasterSettings}
            {Modul1Settings}
            {Modul2Settings}
            {Modul3Settings}
            {Modul4Settings}
          </div>
        );
        break;
      default:
        out = (
          <div>
            {MasterHotspots}
            {MasterSettings}
          </div>
        );
        break;
    }

    return out;
  };

  const addToDatesetList = (controller, adr, name) => {
    let color = randomColor();
    let newLiveDataset = [];
    newLiveDataset.push(...liveDataset);
    newLiveDataset.push({
      id: controller + adr,
      name: name,
      adr: adr,
      color: color,
      controller: controller,
    });

    dispatch(setLiveDataset(newLiveDataset));

    let newList = [];
    newList.push(...chartDataset[controller]);
    newList.push({ [adr]: { color: color } });
    let obj = { ...chartDataset };
    obj[controller] = newList;
    dispatch(setChartDataset(obj));
  };

  const removeFromDatesetList = (controller, adr, listID) => {
    setDisabledGraphList(
      disabledGraphList.filter((item) => item !== controller + adr)
    );
    let newLiveDataset = [];
    newLiveDataset.push(...liveDataset);
    newLiveDataset.splice(listID, 1);
    dispatch(setLiveDataset(newLiveDataset));
    let newList = [];
    chartDataset[controller].forEach((element) => {
      if (adr !== Object.keys(element)[0]) {
        newList.push(element);
      }
    });
    let newChartDataset = {};
    newChartDataset = { ...chartDataset };
    newChartDataset[controller] = newList;
    dispatch(setChartDataset(newChartDataset));
  };

  const handleCloseEditDatasetModal = () => {
    dispatch(setOpenEditDatasetModal(false));
    localStorage.setItem(
      HPData.Serialnumber,
      JSON.stringify({
        chartDataset: chartDataset,
        liveDataset: liveDataset,
        disabledLineGraphs: disabledLineGraphs,
      })
    );
    patchUserGraphSettings(HPData.Serialnumber, {
      chartDataset: chartDataset,
      liveDataset: liveDataset,
      disabledLineGraphs: disabledLineGraphs,
    });
  };

  React.useEffect(() => {
    //console.log(disabledLineGraphs)
    localStorage.setItem(
      HPData.Serialnumber,
      JSON.stringify({
        chartDataset: chartDataset,
        liveDataset: liveDataset,
        disabledLineGraphs: disabledLineGraphs,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledLineGraphs]);

  React.useEffect(() => {
    async function getHistoricData(serialnumber, date) {
      const apiName = "ThermonovaAPI";
      const path = "/HP/historicdata";
      var output = [];
      var historicData;
      var timeStamp;
      await API.get(apiName, path, {
        queryStringParameters: { Serialnumber: serialnumber, date: date },
      })
        .then((response) => {
          //console.log(response)
          timeStamp = Object.keys(response.data)[0];
          fetch(response.url)
            .then((res) => res.json())
            .then(
              (result) => {
                historicData = {
                  data: { [timeStamp]: { changes: result.data } },
                };

                webWorker.postMessage(historicData);
                //console.log(historicData)
              },
              (error) => {
                console.log("fetch error");
              }
            );
        })
        .catch((error) => {
          console.log(error.response);
        });
      return output;
    }
    if (webWorker) {
      if (!historicDataDateList.includes(graphDate) && graphDate !== "") {
        getHistoricData(HPData.Serialnumber, graphDate);
        let arr = [...historicDataDateList];
        arr.push(graphDate);
        dispatch(setHistoricDataDateList(arr));
      }
    }
  }, [dispatch, HPData, graphDate, historicDataDateList, webWorker]);

  return {
    getParamsList,
    getDatasetList,
    showColorPicker,
    openEditDatasetModal,
    handleCloseEditDatasetModal,
    handleChangeDataset,
  };
};
