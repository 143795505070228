import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Box,
  Typography,
  Switch,
  Alert,
  CircularProgress,
  Divider,
  Paper
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

interface CertManagerProps {
  open: boolean;
  onClose: () => void;
  gateway?: any;
  onConfirm: (gatewayId: string, newState: boolean) => Promise<void>;
}

export const CertManager: React.FC<CertManagerProps> = ({ open, onClose, gateway, onConfirm }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleRenewalToggle = async () => {
    if (!gateway) return;
    
    setLoading(true);
    setError(null);
    
    try {
      await onConfirm(gateway.col7, !gateway.renewAllowed);
    } catch (err) {
      setError('Failed to update certificate renewal settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <VpnKeyIcon />
          Certificate Settings
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {/* Gateway Info Section */}
          <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.50', mb: 3 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Gateway ID
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {gateway?.col7 || 'N/A'}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Serial Number
            </Typography>
            <Typography variant="body1">
              {gateway?.col3 || 'N/A'}
            </Typography>
          </Paper>

          <Divider sx={{ my: 2 }} />

          {/* Auto Renewal Section */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Certificate Auto-Renewal
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              When enabled, the gateway will automatically renew its certificates before expiration.
              Disable this if you want to manage certificate renewals manually.
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              bgcolor: 'background.paper',
              p: 2,
              border: 1,
              borderColor: 'divider',
              borderRadius: 1
            }}>
              <Box>
                <Typography variant="subtitle1">
                  Auto-Renewal Status
                </Typography>
                <Typography 
                  variant="body2" 
                  color={gateway?.renewAllowed ? 'success.main' : 'error.main'}
                  sx={{ fontWeight: 'medium' }}
                >
                  {gateway?.renewAllowed ? 'Enabled' : 'Disabled'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Switch
                    checked={gateway?.renewAllowed || false}
                    onChange={handleRenewalToggle}
                    disabled={loading}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          variant="outlined"
          disabled={loading}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};