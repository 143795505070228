import * as React from "react";
import Paper from "@mui/material/Paper";
import DeleteGatewayView from "./DeleteGateway/DeleteGatewayView";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { GTControlLogic } from "./GatewayControlLogic";

export default function BasicTable(props: any) {
  const {
    rows,
    columns,
    QuickSearchToolbar,
    searchText,
    requestSearch,
    openDeleteGWModal,
    closeDeleteGWModal,
    selectedGatewayID,
    renderModals,
  } = GTControlLogic();

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box sx={{ height: props.height, width: 1 }}>
          <DataGrid
            disableSelectionOnClick
            components={{ Toolbar: QuickSearchToolbar }}
            rows={rows}
            columns={columns}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event: any) => {
                  requestSearch(event.target.value);
                },
                clearSearch: () => requestSearch(""),
              },
            }}
          />
        </Box>

        <DeleteGatewayView
          open={openDeleteGWModal}
          handleClose={closeDeleteGWModal}
          selectedGatewayID={selectedGatewayID}
        />
        {renderModals()}
      </Paper>
    </div>
  );
}
