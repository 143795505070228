import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import { setGateways } from "../Redux/MainStore";
import { convertToList } from "../../utils/convertDataStructures";
import { convertToArray } from "../../utils/convertDataStructures";
import { GridColDef } from "@mui/x-data-grid";
import { Gateway } from "../../types";
import { CertManager } from './CertManager';

export const GTControlLogic = () => {
  const Dispatch = useDispatch();

  const [fullRows, setFullRows] = React.useState<Gateway[]>([]);
  const [rows, setRows] = React.useState<Gateway[]>([]);

  const [searchText, setSearchText] = React.useState("");

  const { gateways } = useSelector((state: any) => state.mainData);

  const [openDeleteGWModal, setOpenDeleteGWModal] = React.useState(false);
  const [selectedGatewayID, setSelectedGatewayID] = React.useState("");

  const [openRenewModal, setOpenRenewModal] = React.useState(false);
  const [selectedRenewGateway, setSelectedRenewGateway] = React.useState<Gateway | null>(null);

  console.log({gateways})
  const closeDeleteGWModal = () => {
    setOpenDeleteGWModal(false);
  };

  const closeRenewModal = () => {
    setOpenRenewModal(false);
    setSelectedRenewGateway(null);
  };

  React.useEffect(() => {
    setRows(convertToArray(gateways));
    setFullRows(convertToArray(gateways));
    getData();

    const interval = setInterval(() => {
      getData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRows(convertToArray(gateways));
    setFullRows(convertToArray(gateways));
  }, [gateways]);

  interface CreateData {
    serialNumber: string;
    gatewayID: string;
    connectedFirsttime: string;
    renewAllowed?: boolean;
  }

  function createData({
    serialNumber,
    gatewayID,
    connectedFirsttime,
    renewAllowed,
  }: CreateData): Gateway {
    var date = new Date(Number(connectedFirsttime) * 1000).toLocaleDateString();

    return {
      id: gatewayID,
      col3: serialNumber,
      col7: gatewayID,
      col15: date,
      renewAllowed,
    };
  }

  async function getHPData() {
    const apiName = "ThermonovaAPI";
    const path = "/HP/gatewayid";
    const output: Gateway[] = [];
    await API.get(apiName, path, {})
      .then((response: any) => {
        response?.forEach((item: any) => {
          output.push(
            createData({
              serialNumber: item.serialnumber,
              gatewayID: item.gatewayID,
              connectedFirsttime: item.connectedFirsttime,
              renewAllowed: item.renewAllowed,
            })
          );
        });
      })
      .catch((error) => {
        // console.log(error.response);
      });
    return output;
  }

  function getData() {
    getHPData().then((response) => {
      if (response.length > 0) {
        Dispatch(setGateways(convertToList(response)));
      }
    });
  }

  const handleSetRenewAllowed = async (gatewayId: string, newState: boolean) => {
    try {
      await API.post('ThermonovaAPI', '/gateways/set-renew-allowed', {
        body: {
          gatewayId,
          renewAllowed: newState
        }
      });
      getData(); // Refresh the data
      closeRenewModal();
    } catch (error) {
      console.error('Error setting renew allowed:', error);
    }
  };

  const columns: GridColDef[] = [
    { field: "col3", headerName: "Serial Number", width: 120 },
    { field: "col7", headerName: "Gateway ID", width: 120 },
    { field: "col15", headerName: "Connected First time", width: 150 },
    {
      field: "col12",
      headerName: "Actions",
      width: 350,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {!cellValues.row.col3 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setOpenDeleteGWModal(true);
                  setSelectedGatewayID(cellValues.row.col7);
                }}
              >
                Delete Gateway
              </Button>
            )}
            <Button
              size="small"
              variant="outlined"
              startIcon={<VpnKeyIcon />}
              onClick={() => {
                setOpenRenewModal(true);
                setSelectedRenewGateway(cellValues.row);
              }}
            >
              Certificate Settings
            </Button>
          </Box>
        );
      },
    },
  ];

  function escapeRegExp(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = fullRows.filter((row: any) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  type QuickSearchToolbarProps = {
    clearSearch: () => void;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    value: string;
  };
  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <TextField
          autoFocus
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
    );
  }
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  const renderModals = () => {
    return (
      <CertManager
        open={openRenewModal}
        onClose={closeRenewModal}
        gateway={selectedRenewGateway}
        onConfirm={handleSetRenewAllowed}
      />
    );
  };

  return {
    rows,
    columns,
    QuickSearchToolbar,
    searchText,
    requestSearch,
    openDeleteGWModal,
    closeDeleteGWModal,
    selectedGatewayID,
    renderModals,
  };
};
