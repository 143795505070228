import React from "react";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import HotspotsLogic from "./HotspotsLogic";

export default function HotspotsView(props: any) {
  const { tablebody } = HotspotsLogic(props.Hotspots, props.context);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Modbus Adress</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        {tablebody}
      </Table>
    </TableContainer>
  );
}
